import { format } from "date-fns";

export const formatDate = (string) => {
  return format(
    new Date(
      string.substring(0, 4),
      string.substring(5, 7),
      string.substring(8, 10)
    ),
    "yyyyMMdd"
  );
};

export const transformToDate = (string) => {
  return new Date(
    string.substring(0, 4),
    string.substring(5, 7),
    string.substring(8, 10)
  );
};


